html,
body,
#root {
	height: 100%;
	padding: 0;
	margin: 0;
	overflow: auto;
	box-sizing: content-box;
}

.App {
	font-family: sans-serif;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	&:focus {
		outline: none;
	}
}

.setting {
	align-self: flex-start;
	padding: 5px 10px;

	button {
		font-size: 15px;
	}

	input {
		font-size: 20px;
	}
}

.topWall {
	border-top: 2px solid black;
}

.rightWall {
	border-right: 2px solid black;
}

.bottomWall {
	border-bottom: 2px solid black;
}

.leftWall {
	border-left: 2px solid black;
}

.sol {
	div {
		height: 50%;
		width: 50%;
		border-radius: 50%;
		background: peachpuff;
		display: inline-block;
	}
}

.currentPosition {
	div {
		background: salmon;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		display: block;
	}
}

.destination {
	div {
		background: SpringGreen;
		height: 100%;
		width: 100%;
		border-radius: 50%;
		display: block;
	}
}

.currentPosition.destination {
	div {
		animation: rainbowBall 5s infinite;
	}
}

@keyframes rainbowBall {
	0% {
		background-color: gold;
	}
	15% {
		background-color: coral;
	}
	29% {
		background-color: papayawhip;
	}
	43% {
		background-color: darkseagreen;
	}
	57% {
		background-color: lightcyan;
	}
	71% {
		background-color: dodgerblue;
	}
	84% {
		background-color: violet;
	}
	100% {
		background-color: indigo;
	}
}

#maze {
	margin-top: 15px;
	border-collapse: collapse;
	td {
		height: 30px;
		width: 30px;
	}
}

.info {
	margin-top: 20px;
	width: 100%;
	background: cornsilk;
	padding: 3px;
	font-size: 20px;
	p {
		text-transform: uppercase;
		color: forestgreen;
	}
}
